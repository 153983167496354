"use strict";

joint.custom || (joint.custom = {});

joint.custom.Halo = joint.mvc.View.extend({

    className: 'custom-halo',

    events: {
        'mousedown .custom-halo-pie': 'onMouseDownPie',
        'mousedown .custom-halo-tool': 'onMouseDownTool'
    },

    options: {
        slices: [{
            link: function() {
                return new joint.dia.Link();
            }
        }],
        tools: [],
        sliceInnerRadius: 16,
        sliceOuterRadius: 50,
        sliceHighlightedInnerRadius: 16,
        sliceHighlightedOuterRadius: 55,
        sliceshadowRadius: 10,
        previewMode: false,
        piePosition: function(bbox) {
            return bbox.rightMiddle();
        }
    },

    vSlices: [],
    vSliceshadows: [],
    vSlicesContainer: null,

    init: function() {

        _.bindAll(
            this,
            'onMouseUp',
            'onMouseLeave',
            'onMouseLeaveSlices',
            'onMouseOverSlices',
            'createLinkView',
            'updateLinkView',
            'connectLinkView'
        );

        this.render();
        this.capturePointerEvents();
        this.update();
        this.startListening();

        this.$document = $(document);
    },

    startListening: function() {
        var options = this.options;
        this.listenTo(options.model, 'change:position change:size', this.update)
            .listenTo(options.model, 'remove', this.remove)
            .listenTo(options.paper.model, 'reset', this.remove)
            .listenTo(options.paper, 'scale translate', this.update);
    },

    render: function() {
        var options = this.options;
        if (!options.previewMode) {
            var slices = options.slices;
            this.piePosition = this.resolvePosition(options.piePosition);
            this.renderSlices(slices);
        }

        this.$el.append(this.renderTools(options.tools));

        if (!options.previewMode) {
            if (slices.length > 0) {
                this.$el
                    .append(this.renderPie(slices[this.getDefaultIndex()].color || ''))
                    .append(this.renderText());
            }
        }

        this.$el.appendTo(options.paper.el);
    },

    renderText: function() {
        return (this.$text = $('<div/>').addClass('custom-halo-text'));
    },

    renderSlices: function(slices) {
        var vSlicesContainer = V('g')
            .addClass('custom-halo-slices')
            .append(
                _.chain(slices).map(this.renderSlice, this).flatten().value()
            );
        this.vSlicesContainer = vSlicesContainer;
        this.$slicesContainer = $(vSlicesContainer.node);
        return vSlicesContainer;
    },

    renderSlice: function(slice, index) {
        return [
            (this.vSlices[index] = V('path')
                .addClass('custom-halo-slice')
                .attr({
                    'data-index': index,
                    fill: slice.color
                })),
            (this.vSliceshadows[index] = V('path')
                .addClass('custom-halo-slice-shadow'))
        ];
    },

    renderPie: function(color) {
        return (this.$pie = $('<div/>')
            .addClass('custom-halo-pie')
            .css({
                backgroundColor: color,
                left: this.piePosition.x,
                top: this.piePosition.y,
                transform: 'rotate(' + this.getSliceAngleOffset() + 'deg)'
            }));
    },

    renderTools: function(tools) {
        return _.map(tools, this.renderTool, this);
    },

    renderTool: function(tool, index) {
        var position = this.resolvePosition(tool.position);
        return $('<div/>')
            .addClass('custom-halo-tool')
            .attr('data-index', this.options.previewMode ? 1 : index)
            .css({
                backgroundColor: tool.color,
                left: position.x,
                top: position.y
            });
    },

    update: function() {

        var matrix = this.options.paper.viewport.getCTM();
        var elBBox = this.model.getBBox();
        var transformedPosition = V.transformPoint(elBBox, matrix);

        this.$el.css({
            left: transformedPosition.x,
            top: transformedPosition.y,
            width: elBBox.width,
            height: elBBox.height,
            transform: 'scale(' + matrix.a + ',' + matrix.d + ')'
        });

        if (!this.options.previewMode) {
            this.vSlicesContainer.translate(
                elBBox.x + this.piePosition.x,
                elBBox.y + this.piePosition.y, {
                    absolute: true
                }
            );
        }

    },

    resolvePosition: function(fn) {
        return _.isFunction(fn) ?
            fn.call(this, g.Rect(this.model.get('size'))) :
            g.Point(0, 0);
    },

    getSliceAngleOffset: function() {
        return -g.Rect(this.model.get('size')).center().theta(this.piePosition);
    },

    calculateSlicePathData: function(index, innerRadius, outerRadius) {

        var options = this.options;
        var count = options.slices.length;
        var sliceRadius = 180 / count;
        var startAngle = (index === 0) ? -180 : ((index) * sliceRadius - 90);
        var endAngle = (index === count - 1) ? 180 : ((index + 1) * sliceRadius - 90);
        var angleOffset = this.getSliceAngleOffset();
        return V.createSlicePathData(
            innerRadius,
            outerRadius,
            g.toRad(startAngle + angleOffset, true),
            g.toRad(endAngle + angleOffset, true)
        );
    },

    onMouseDownPie: function() {
        this.model.startBatch('custom-halo').toFront({
            customHalo: this.cid
        });
        this.showSlices();
        this.bindEvents();
    },

    onMouseDownTool: function(evt) {
        var index = $(evt.target).attr('data-index');
        var tool = this.options.tools[this.options.previewMode ? 0 : index];
        if (_.isFunction(tool.action)) {
            tool.action.call(this, this.model);
        }
    },

    onMouseOverSlices: function(evt) {
        var selectedIndex = evt.data.selectedIndex = $(evt.target).attr('data-index') || null;
        if (selectedIndex !== null) {
            selectedIndex = parseInt(selectedIndex, 10);
        }
        this.highlightSlices(selectedIndex);
    },

    onMouseLeave: function(evt) {
        this.ignorePointerEvents();
        var $relatedTarget = $(evt.relatedTarget);
        if (!$relatedTarget.hasClass('custom-halo-slice')) {
            var selectedIndex = this.getDefaultIndex();
            var linkView = evt.data.linkView = this.createLinkView(selectedIndex);
        }
        if (linkView) {
            this.hideSlices();
            this.hide();
        }
    },

    onMouseLeaveSlices: function(evt) {
        this.ignorePointerEvents();
        var sharedData = evt.data;
        var linkView = sharedData.linkView = this.createLinkView(sharedData.selectedIndex);
        if (linkView) {
            this.hideSlices();
            this.hide();
        }
    },

    onMouseUp: function(evt) {
        this.connectLinkView(evt);
        this.unbindEvents();
        this.hideSlices();
        this.show();
        this.capturePointerEvents();
        var sharedData = evt.data;
        this.model.stopBatch('custom-halo').set('z', sharedData.zIndex, {
            customHalo: this.cid
        });
        this.clearSharedData(sharedData);
    },

    showSlices: function() {
        var options = this.options;
        if (options.slices.length > 1) {
            var paper = options.paper;
            this.unhighlightSlices();
            this.model.findView(paper).vel.before(this.vSlicesContainer);
        }
    },

    hide: function() {
        this.$el.addClass('custom-halo-hidden');
    },

    show: function() {
        this.$el.removeClass('custom-halo-hidden');
    },

    hideSlices: function() {
        this.$text.text('');
        this.vSlicesContainer.remove();
    },

    highlightSlices: function(selectedIndex) {
        this.unhighlightSlices();
        if (selectedIndex !== null) {
            this.highlightSliceByIndex(selectedIndex);
            var slice = this.options.slices[selectedIndex];
            this.$text
                .text(slice.title || '')
                .css('color', slice.color);
        }
    },

    unhighlightSlices: function() {
        _.times(this.vSlices.length).forEach(this.unhighlightSliceByIndex, this);
    },

    highlightSliceByIndex: function(index) {
        var options = this.options;
        this.vSlices[index]
            .addClass('custom-halo-highlighted')
            .attr('d', this.calculateSlicePathData(
                index,
                options.sliceHighlightedInnerRadius,
                options.sliceHighlightedOuterRadius
            ));
        this.vSliceshadows[index]
            .attr('d', this.calculateSlicePathData(
                index,
                options.sliceHighlightedOuterRadius - options.sliceshadowRadius,
                options.sliceHighlightedOuterRadius
            ));
    },

    unhighlightSliceByIndex: function(index) {
        var options = this.options;
        this.vSlices[index]
            .removeClass('custom-halo-highlighted')
            .attr('d', this.calculateSlicePathData(
                index,
                options.sliceInnerRadius,
                options.sliceOuterRadius
            ));
        this.vSliceshadows[index]
            .attr('d', this.calculateSlicePathData(
                index,
                options.sliceOuterRadius - options.sliceshadowRadius,
                options.sliceOuterRadius
            ));
    },

    ignorePointerEvents: function() {
        this.$el.addClass('custom-halo-inactive');
    },

    capturePointerEvents: function() {
        this.$el.removeClass('custom-halo-inactive');
    },

    getDefaultIndex: function() {
        var index = _.findIndex(this.options.slices, {
            defaultSlice: true
        });
        return (index >= 0) ? index : 0;
    },

    createLinkView: function(selectedIndex) {
        var linkView;
        var options = this.options;
        var model = this.model;
        var slice = options.slices[selectedIndex];

        if (slice) {
            var link = slice.link.call(this, model, selectedIndex);

            if (link instanceof joint.dia.Link) {
                if (slice.typeId) {
                    link.prop('typeId', slice.typeId);
                }
                if (slice.value) {
                    link.prop('value', slice.value);
                }
                this.trigger('linking:start');
                linkView = link
                    .set('source', {
                        id: model.id
                    })
                    .addTo(options.paper.model, {
                        validation: false,
                        async: false,
                        customHalo: this.cid
                    })
                    .findView(options.paper);
                linkView.startArrowheadMove('target', {
                    whenNotAllowed: 'remove'
                });
            }
        }
        return linkView || null;
    },

    updateLinkView: function(evt) {
        var linkView = evt.data.linkView;
        if (linkView) {
            var clientCoords = this.options.paper.snapToGrid({
                x: evt.clientX,
                y: evt.clientY
            });
            linkView.pointermove(evt, clientCoords.x, clientCoords.y);
        }
    },

    connectLinkView: function(evt) {
        var sharedData = evt.data;
        var linkView = sharedData.linkView;
        if (linkView) {
            var clientCoords = this.options.paper.snapToGrid({
                x: evt.clientX,
                y: evt.clientY
            });
            linkView.pointerup(evt, clientCoords.x, clientCoords.y);
            this.trigger('linking:end');
        }
    },

    createSharedData: function() {
        return {
            zIndex: this.model.get('z'),
            selectedIndex: null,
            linkView: null
        };
    },

    clearSharedData: function(sharedData) {
        sharedData.selectedIndex = null;
        sharedData.linkView = null;
    },

    bindEvents: function() {
        var sharedData = this.createSharedData();
        var ns = this.getEventNamespace();
        this.$el.on('mouseleave' + ns, sharedData, this.onMouseLeave);
        this.$document.on('mouseup' + ns, sharedData, this.onMouseUp);
        this.$document.on('mousemove' + ns, sharedData, this.updateLinkView);
        this.$slicesContainer.on('mouseover' + ns, sharedData, this.onMouseOverSlices);
        this.$slicesContainer.on('mouseleave', +ns, sharedData, this.onMouseLeaveSlices);
    },

    unbindEvents: function() {
        var ns = this.getEventNamespace();
        this.$el.off(ns);
        this.$document.off(ns);

        if (!this.options.previewMode) {
            this.$slicesContainer.off(ns);
        }
    },

    getEventNamespace: function() {
        return '.custom-halo-ns-' + this.cid;
    },

    onRemove: function() {
        this.unbindEvents();
    }

}, {

    open: function(elementView, opt) {

        var haloOptions = _.defaults({
            model: elementView.model,
            paper: elementView.paper
        }, opt);

        return (new this(haloOptions));
    }
});
